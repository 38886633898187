import React, {useEffect, useState} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation, Pagination, Scrollbar, A11y} from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'tippy.js/dist/tippy.css';
import {MdKeyboardArrowRight, MdKeyboardArrowLeft} from 'react-icons/md';
import Link from 'next/link';
import {CollectionResponseInterface, getCollectionApi} from '@api/collection';
import {TextEllipsis} from 'neka-simple-utilities';
import resolveImage from '@helpers/resolve-image';

const ArtsCarousel = () => {
  const [collection, setCollection] = useState<CollectionResponseInterface[]>([]);

  useEffect(() => {
    const getCuratedCollection = async () => {
      const data = await getCollectionApi({
        page: 1,
        isCurated: true,
      });
      data?.records.reverse();
      setCollection(data?.records);
    };
    getCuratedCollection();
  }, []);

  return (
    <>
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        slidesPerView="auto"
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          900: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1100: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
        }}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
        className=" card-slider-4-columns !py-5"
      >
        {collection?.map((item) => {
          // .split('/')
          // .slice(-1)
          // .toString()
          // .replace('.jpg', '')
          // .replace('.gif', '')
          // .replace('_lg', '');

          return (
            <SwiperSlide key={item.id}>
              <article>
                <div className="dark:bg-black-green rounded-lg block overflow-hidden bg-white shadow-md transition-shadow hover:shadow-lg">
                  <figure className="relative">
                    <Link href={`/collection/${item.slug}`}>
                      <a>
                        <img
                          src={
                            item.featured_image
                              ? `${resolveImage(item.featured_image)}`
                              : `${resolveImage(item.logo_image)}`
                          }
                          alt="item 1"
                          height="400"
                          width="532"
                          // layout="responsive"
                          className="swiper-lazy aspect-[4/3] object-cover swiper-lazy-loaded rounded-xl"
                        />
                      </a>
                    </Link>
                  </figure>
                  <div className="p-6">
                    <div className="flex">
                      <Link href={`/user/${item.creator_address}`}>
                        <a className="shrink-0 mr-4">
                          {/* user image */}
                          <img
                            src={`${resolveImage(
                              item.Creator.profile_picture,
                              '/images/user/user_avatar_new.png',
                            )}`}
                            alt={item.slug}
                            height={40}
                            width={40}
                            className="mr-4 h-10 w-10 rounded-full"
                          />
                        </a>
                      </Link>
                      <div>
                        <Link href={`/collection/${item.slug}`}>
                          <a className="block">
                            <span className="font-display hover:text-accent text-dark-navy text-lg leading-none dark:text-white">
                              {item.name}
                            </span>
                          </a>
                        </Link>
                        <Link href={`/user/${item.creator_address}`}>
                          {item.Creator.username ? (
                            <a className="font-bold text-transparent bg-clip-text bg-green-gradation-light text-2xs">
                              {item.Creator.username}
                            </a>
                          ) : (
                            <a className="font-bold text-transparent bg-clip-text bg-green-gradation-light text-2xs">
                              {TextEllipsis({
                                text: item.creator_address,
                                position: 'middle',
                                length: 6,
                              })}
                            </a>
                          )}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            </SwiperSlide>
          );
        })}
      </Swiper>

      {/* <!-- Slider Navigation --> */}
      <div className="group swiper-button-prev shadow-white-volume absolute !top-1/2 !-left-4 z-10 -mt-6 flex !h-12 !w-12 cursor-pointer items-center justify-center rounded-full bg-white p-3 text-base sm:!-left-6 after:hidden">
        <MdKeyboardArrowLeft />
      </div>
      <div className="group swiper-button-next shadow-white-volume absolute !top-1/2 !-right-4 z-10 -mt-6 flex !h-12 !w-12 cursor-pointer items-center justify-center rounded-full bg-white p-3 text-base sm:!-right-6 after:hidden">
        <MdKeyboardArrowRight />
      </div>
    </>
  );
};

export default ArtsCarousel;
