import React from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Controller, Navigation, Pagination, Scrollbar} from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'tippy.js/dist/tippy.css';
import Link from 'next/link';
import {MdKeyboardArrowRight, MdKeyboardArrowLeft} from 'react-icons/md';
import {ItemInterfaceListed, ItemInterfaceSold} from '@api/item';
import resolveImage from '@helpers/resolve-image';
import {getItemPrice, getItemPriceUnit} from '@helpers/item-card';
import Image from 'next/image';
import {formatPrice} from '@helpers/number-format';

const NetworkIcon = () => {
  return (
    <svg
      className="fill-grey"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10.6842 4.53158C10.4263 4.38421 10.0947 4.38421 9.8 4.53158L7.73684 5.74737L6.33684 6.52105L4.31053 7.73684C4.05263 7.88421 3.72105 7.88421 3.42632 7.73684L1.84211 6.77895C1.58421 6.63158 1.4 6.33684 1.4 6.00526V4.16316C1.4 3.86842 1.54737 3.57369 1.84211 3.38947L3.42632 2.46842C3.68421 2.32105 4.01579 2.32105 4.31053 2.46842L5.89474 3.42632C6.15263 3.57369 6.33684 3.86842 6.33684 4.2V5.41579L7.73684 4.60526V3.35263C7.73684 3.0579 7.58947 2.76316 7.29474 2.57895L4.34737 0.847369C4.08947 0.700001 3.75789 0.700001 3.46316 0.847369L0.442105 2.61579C0.147368 2.76316 0 3.0579 0 3.35263V6.81579C0 7.11053 0.147368 7.40526 0.442105 7.58948L3.42632 9.32105C3.68421 9.46842 4.01579 9.46842 4.31053 9.32105L6.33684 8.14211L7.73684 7.33158L9.76316 6.15263C10.0211 6.00526 10.3526 6.00526 10.6474 6.15263L12.2316 7.07369C12.4895 7.22105 12.6737 7.51579 12.6737 7.84737V9.68948C12.6737 9.98421 12.5263 10.2789 12.2316 10.4632L10.6842 11.3842C10.4263 11.5316 10.0947 11.5316 9.8 11.3842L8.21579 10.4632C7.95789 10.3158 7.77368 10.0211 7.77368 9.68948V8.51053L6.37368 9.32105V10.5368C6.37368 10.8316 6.52105 11.1263 6.81579 11.3105L9.8 13.0421C10.0579 13.1895 10.3895 13.1895 10.6842 13.0421L13.6684 11.3105C13.9263 11.1632 14.1105 10.8684 14.1105 10.5368V7.03684C14.1105 6.74211 13.9632 6.44737 13.6684 6.26316L10.6842 4.53158Z" />
    </svg>
  );
};

const RecentlyCarousel = ({
  relatedItem,
  tag,
}: {
  relatedItem?: Array<ItemInterfaceSold | ItemInterfaceListed>;
  tag: string;
}) => {
  return (
    <>
      <Swiper
        modules={[Controller, Navigation, Pagination, Scrollbar]}
        spaceBetween={50}
        slidesPerView="auto"
        breakpoints={{
          600: {
            slidesPerView: 1,
          },
          900: {
            slidesPerView: 2,
          },
          1100: {
            slidesPerView: 4,
          },
        }}
        navigation={{
          nextEl: `.bids-swiper-button-next-${tag}`,
          prevEl: `.bids-swiper-button-prev-${tag}`,
        }}
        className=" card-slider-4-columns !py-5"
      >
        {relatedItem &&
          relatedItem.map((item, idx) => {
            const {itemId, image, name, optimizedImage} = item;

            return (
              <SwiperSlide className="text-white" key={`${itemId}-${idx}`}>
                <article key={itemId}>
                  <div className="dark:bg-black-green-2 dark:border-black-green-2 border-jacarta-100 rounded-2.5xl block border bg-white p-[1.1875rem] transition-shadow hover:shadow-lg">
                    <figure className="relative">
                      <Link href={`/item/${itemId}`}>
                        <a>
                          <div
                            className="w-full pb-[100%] bg-center bg-no-repeat bg-cover rounded-[0.625rem] mx-auto"
                            style={{
                              backgroundImage: `url('${resolveImage(
                                optimizedImage || image,
                                '/images/banner-default.png',
                              )}')`,
                            }}
                          ></div>
                        </a>
                      </Link>
                    </figure>
                    <div className="mt-7 flex items-center justify-between">
                      <Link href={`/item/${itemId}`}>
                        <a className="w-full">
                          <div
                            className="truncate font-display text-dark-navy hover:text-dark-grey text-base dark:text-white"
                            title={name}
                          >
                            {name}
                          </div>
                        </a>
                      </Link>
                    </div>
                    <div className="mt-2 text-sm flex justify-between">
                      <span className="dark:text-white text-dark-grey mr-1 flex gap-x-1 items-center font-semibold">
                        <>
                          <Image
                            src={item.currencyLogoUrl}
                            height={18}
                            width={18}
                            objectFit={'cover'}
                            alt={item.currencySymbol}
                          />
                          {formatPrice(
                            getItemPriceUnit(item.price, item.currencyDecimals),
                            item.currency,
                          )}
                        </>
                      </span>
                      <span className="text-grey">
                        {(item as ItemInterfaceListed | ItemInterfaceSold).duration}
                      </span>
                    </div>
                  </div>
                </article>
              </SwiperSlide>
            );
          })}
      </Swiper>
      {/* <!-- Slider Navigation --> */}
      <div
        className={`group bids-swiper-button-prev-${tag} swiper-button-prev shadow-white-volume absolute !top-1/2 !-left-4 z-10 -mt-6 flex !h-12 !w-12 cursor-pointer items-center justify-center rounded-full bg-white p-3 text-base sm:!-left-6 after:hidden`}
      >
        <MdKeyboardArrowLeft />
      </div>
      <div
        className={`group bids-swiper-button-next-${tag} swiper-button-next shadow-white-volume absolute !top-1/2 !-right-4 z-10 -mt-6 flex !h-12 !w-12 cursor-pointer items-center justify-center rounded-full bg-white p-3 text-base sm:!-right-6 after:hidden`}
      >
        <MdKeyboardArrowRight />
      </div>
    </>
  );
};

export default RecentlyCarousel;
