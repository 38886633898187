import {NewsInterface} from '@api/common';
import {getImage} from '@helpers/news';
import {Typography} from '@mui/material';
import {format} from 'date-fns';
import {TextEllipsis} from 'neka-simple-utilities';
import Link from 'next/link';
import React from 'react';

const NewsItemHome = ({data, type}: {data: NewsInterface; type?: string}) => {
  if (type === 'main') {
    return (
      <Link
        href={{
          pathname: `/news/${data?.attributes?.slug}`,
        }}
      >
        <div className="dark:bg-black-green rounded-2xl block overflow-hidden bg-white shadow-md transition-shadow hover:shadow-lg lg:col-span-2 lg:row-span-2 cursor-pointer">
          <figure className="relative">
            <div
              style={{
                borderRadius: '20px',
                backgroundImage: `url('${
                  getImage(data)
                    ? process.env.NEXT_PUBLIC_URL_CMS + getImage(data)
                    : '/images/avatars/avatar_17_rounded.jpg'
                }')`,
              }}
              className={`relative lg:h-[400px] bg-center bg-cover bg-no-repeat cursor-pointer md:h-[400px] h-[300px]`}
            ></div>
          </figure>
          <div className="px-6 py-3">
            <Typography
              noWrap
              className="!font-display hover:text-accent text-dark-navy !text-[18px] dark:text-white !mb-2"
            >
              {data?.attributes?.title}
            </Typography>
            <div className="font-display-2 text-dark-navy dark:text-grey text-base font-normal mb-2">
              {data?.attributes?.summary
                ? data?.attributes?.summary?.length > 300
                  ? TextEllipsis({position: 'end', length: 300, text: data?.attributes?.summary})
                  : data?.attributes?.summary
                : '-'}
            </div>
            <div className="font-display-2 text-dark-navy dark:text-grey text-sm font-normal mb-3 text-right">
              {format(new Date(data?.attributes?.publishedAt), 'EEEE, dd MMMM YYY')}
            </div>
          </div>
        </div>
      </Link>
    );
  }
  return (
    <Link
      href={{
        pathname: `/news/${data?.attributes?.slug}`,
      }}
    >
      <div
        style={{
          borderRadius: '20px',
          backgroundImage: `url('${
            getImage(data)
              ? process.env.NEXT_PUBLIC_URL_CMS + getImage(data)
              : '/images/avatars/avatar_17_rounded.jpg'
          }')`,
        }}
        className={
          type === 'minimalize'
            ? `relative bg-center bg-cover bg-no-repeat cursor-pointer h-[300px]`
            : `relative lg:h-full bg-center bg-cover bg-no-repeat cursor-pointer md:h-[400px] h-[300px]`
        }
      >
        <div
          className="absolute w-full bottom-0 px-3 pt-5"
          style={{
            background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 95%)',
            borderRadius: '0 0 20px 20px',
          }}
        >
          <Typography
            noWrap
            className="!font-display hover:text-accent text-dark-navy !text-[18px] dark:text-white"
          >
            {data?.attributes?.title}
          </Typography>
          <div className="font-display-2 text-dark-navy dark:text-grey text-sm font-normal mb-3 text-right">
            {format(new Date(data?.attributes?.publishedAt), 'EEEE, dd MMMM YYY')}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default NewsItemHome;
