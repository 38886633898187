import {ItemActiveListing, ItemInterface, ItemInterfaceSold, ListingType} from '@api/item';
import {ethers} from 'ethers';
import {formatPrice, scientificToDecimal} from './number-format';

export const getText = (item: ItemInterface, userAddress?: string) => {
  const items = item?.listings?.filter((item: ItemActiveListing) => {
    return (item.endTime as number) > Math.floor(Date.now() / 1000);
  });

  if (items.length > 0) {
    const auctions = items?.find((item: ItemActiveListing) => {
      return item.listingType === ListingType.Auction;
    });

    if (auctions) {
      if ((auctions?.lister?.wallet_address as any) === userAddress) {
        return auctions.offers?.length > 0 ? 'View Auction' : 'Cancel Auction';
      } else return 'View Auction';
    } else {
      const directs = items?.filter((item: ItemActiveListing) => {
        return item.listingType === ListingType.Direct;
      });

      const isOwner = directs?.find((item: ItemActiveListing) => {
        return item.lister.wallet_address === userAddress;
      });

      return isOwner ? 'Cancel Listing' : 'Buy';
    }
  }
};

export const getItemPrice = (item: ItemInterface) => {
  if (item?.listings?.length > 0) {
    const listings = item?.listings?.filter((listing: ItemActiveListing) => {
      return (listing.endTime as number) > Math.floor(Date.now() / 1000);
    });

    if (listings.length > 0) {
      const auctions = listings?.find((item: ItemActiveListing) => {
        return item.listingType === ListingType.Auction;
      });

      if (auctions) {
        let price;
        if (auctions?.offers?.length > 0) {
          price = ethers.utils.formatUnits(auctions.highestOffer.price, auctions.Currency.decimals);
        } else {
          price = ethers.utils.formatUnits(
            auctions.reservePricePerToken,
            auctions.Currency.decimals,
          );
        }
        return formatPrice(price, auctions.Currency?.symbol);
      } else {
        const listing = listings.reduce(
          (previous: ItemActiveListing, current: ItemActiveListing) => {
            return ethers.utils.formatUnits(
              current.buyoutPricePerToken,
              current.Currency.decimals,
            ) < ethers.utils.formatUnits(previous.buyoutPricePerToken, previous.Currency.decimals)
              ? current
              : previous;
          },
        );
        const price = ethers.utils.formatUnits(
          listing.buyoutPricePerToken,
          listing.Currency.decimals,
        );

        return formatPrice(price, listing.Currency?.symbol);
      }
    } else return '-';
  } else return '-';
};

export const getActiveListing = (item: ItemInterface) => {
  if (item?.listings?.length == 0) {
    return null;
  }

  const activeListings = item?.listings?.filter((listing: ItemActiveListing) => {
    return (listing.endTime as number) > Math.floor(Date.now() / 1000);
  });
  if (activeListings.length == 0) {
    return null;
  }

  const auction = activeListings?.find((item: ItemActiveListing) => {
    return item.listingType === ListingType.Auction;
  });

  if (auction) {
    return auction;
  }

  const listing = activeListings.reduce(
    (previous: ItemActiveListing, current: ItemActiveListing) => {
      return ethers.utils.formatUnits(current.buyoutPricePerToken, current.Currency?.decimals) <
        ethers.utils.formatUnits(previous.buyoutPricePerToken, previous.Currency?.decimals)
        ? current
        : previous;
    },
  );

  return listing;
};

export const getAuctionItemPrice = (auction: ItemActiveListing) => {
  if (auction?.offers?.length > 0) {
    return ethers.utils.formatUnits(auction.highestOffer.price, auction.Currency?.decimals);
  }
  return ethers.utils.formatUnits(auction.reservePricePerToken, auction.Currency?.decimals);
};

export const getItemPriceUnit = (price: string, decimals?: number) => {
  return ethers.utils.formatUnits(price ?? '0', decimals ?? 0);
};

export const activeListingExists = (item: ItemInterface) => {
  if (item?.listings?.length > 0) {
    const items = item?.listings?.find((item: ItemActiveListing) => {
      return (item.endTime as number) > Math.floor(Date.now() / 1000);
    });

    if (items) return true;
    else return false;
  } else return false;
};
