import React, {useEffect, useState} from 'react';
import ArtsCarousel from '../carousel/artCarousel';
import Link from 'next/link';
import {useTranslation} from 'react-i18next';
import {getTopCollections, TopCollectionInterface, TopCollectionSortRange} from '@api/collection';
import RecentlyCarousel from '@components/carousel/recentlyCarousel';
import {
  getRecentlyListed,
  getRecentlySold,
  ItemInterfaceListed,
  ItemInterfaceSold,
} from '@api/item';
import HomeNews from '@components/news/homeNews';
import TopCollection from '@components/collections/Top_Collection';

function getDuration(seconds: number): string {
  if (seconds > 365 * 24 * 60 * 60) {
    return `${Math.floor(seconds / (365 * 24 * 60 * 60))} years ago`;
  } else if (seconds > 30 * 24 * 60 * 60) {
    return `${Math.floor(seconds / (30 * 24 * 60 * 60))} months ago`;
  } else if (seconds > 24 * 60 * 60) {
    return `${Math.floor(seconds / (24 * 60 * 60))} days ago`;
  } else if (seconds > 60 * 60) {
    return `${Math.floor(seconds / (60 * 60))} hours ago`;
  } else if (seconds > 60) {
    return `${Math.floor(seconds / 60)} minute ago`;
  } else {
    return `${Math.floor(seconds)} second ago`;
  }
}

const Hero = () => {
  const {t} = useTranslation(['home']);
  const [topCollections, setTopCollections] = useState<Array<TopCollectionInterface>>([]);
  const [selectedRange, setSelectedRange] = useState<TopCollectionSortRange>(
    TopCollectionSortRange.LAST24HOURS,
  );
  const [recentlySold, setRecentlySold] = useState<Array<ItemInterfaceSold>>([]);
  const [recentlyListed, setRecentlyListed] = useState<Array<ItemInterfaceListed>>([]);
  const [large, setLarge] = useState(false);

  useEffect(() => {
    const windowChange = () => {
      setLarge(window.innerWidth > 1200);
    };
    windowChange();
    window.addEventListener('resize', windowChange);

    return () => {
      window.removeEventListener('resize', windowChange);
    };
  }, []);

  useEffect(() => {
    const currentDate = new Date().getTime();

    getTopCollections({sort: selectedRange}).then((res) => {
      setTopCollections(res.length >= 1 ? res : []);
    });

    getRecentlySold().then((res) => {
      setRecentlySold(
        res.records?.map((val) => {
          val.type = 'sold';
          const seconds = Math.floor(currentDate / 1000) - val.createdAt;
          val.duration = getDuration(seconds);
          return val;
        }),
      );
    });

    getRecentlyListed().then((res) => {
      setRecentlyListed(
        res.records.map((val) => {
          val.type = 'listed';
          const seconds = Math.floor(currentDate / 1000) - val.createdAt;
          val.duration = getDuration(seconds);
          return val;
        }),
      );
    });
  }, []);

  useEffect(() => {
    if (selectedRange) {
      getTopCollections({sort: selectedRange}).then((res) => {
        setTopCollections(res.length >= 1 ? res : []);
      });
    }
  }, [selectedRange]);

  return (
    <>
      {/* <!-- Hero --> */}
      <section className="hero relative pt-20 md:pt-32 bg-green-radial">
        <div className="container">
          <div className="py-24 text-center">
            <h1 className="text-graphite-black normal-case font-display mb-6 text-5xl dark:text-white lg:text-6xl xl:text-7xl">
              {t('home:title')}
            </h1>
            <p className="mb-8 text-xl px-4 text text-grey">{t('home:subtitle')}</p>
            <div className="inline-flex space-x-4">
              <Link href="/create">
                <a className="bg-green-gradation-light w-36 rounded-full py-3 px-8 text-center font-semibold text-white">
                  {t('home:create')}
                </a>
              </Link>
              <Link href="/collection/explore-collection">
                <a className="bg-green-gradation-light w-36 rounded-full py-3 px-8 text-center font-semibold text-white">
                  {t('home:explore')}
                </a>
              </Link>
            </div>
          </div>

          <div className="relative">
            {/* <!-- Slider --> */}
            <ArtsCarousel />
          </div>
        </div>
      </section>
      {/* <!-- end hero --> */}
      {/* <- top collection -> */}
      <section className="hero relative py-5 md:pt-32">
        <div className="container">
          <div className="text-center">
            <h1 className="text-graphite-black normal-case font-display mb-6 text-2xl dark:text-white lg:text-3xl xl:text-4xl">
              {t('home:top-collections')}
            </h1>
            <div className="flex my-5 justify-end">
              <select
                onChange={(e) => setSelectedRange(e.target.value as TopCollectionSortRange)}
                defaultValue={selectedRange}
                className="dark:bg-white/30 dark:border-bg-white border-jacarta-100 min-w-[12rem] rounded-lg text-sm dark:text-white"
              >
                {Object.values(TopCollectionSortRange).map((val, index) => {
                  return (
                    <option key={index} value={val} className="dark:bg-black/60">
                      {val.replaceAll('_', ' ')}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          <div
            className={`relative dark:bg-black-green-2 bg-white shadow-base rounded-lg ${
              large ? 'p-3' : 'p-5'
            }`}
          >
            <TopCollection
              collections={topCollections}
              column={[
                {name: t('home:table.collection'), className: 'grow w-[60%] font-display'},
                {name: t('home:table.floor-price'), className: 'shrink w-[20%] font-display'},
                {name: t('home:table.volume'), className: 'shrink w-[20%] font-display'},
              ]}
              large={large}
            />
          </div>
        </div>
      </section>
      {/* <- end top collection -> */}
      {/* <- top collection -> */}

      {/* Recently Sold */}
      <section className="hero relative py-5 pb-10 md:pt-32">
        <div className="container">
          <div className="text-center">
            <h1 className="text-graphite-black normal-case font-display mb-6 text-2xl dark:text-white lg:text-3xl xl:text-4xl">
              {t('home:recently-sold')}
            </h1>
          </div>
          <div className="relative">
            {/* <!-- Slider --> */}
            <RecentlyCarousel relatedItem={recentlySold} tag="sold" />
          </div>
        </div>
      </section>
      {/* End recently sold */}

      {/* Recently Listed */}
      <section className="hero relative py-5 pb-10 md:pt-32">
        <div className="container">
          <div className="text-center">
            <h1 className="text-graphite-black normal-case font-display mb-6 text-2xl dark:text-white lg:text-3xl xl:text-4xl">
              {t('home:recently-listed')}
            </h1>
          </div>
          <div className="relative">
            {/* <!-- Slider --> */}
            <RecentlyCarousel relatedItem={recentlyListed} tag="listed" />
          </div>
        </div>
      </section>
      {/* End recently listed */}

      {/* News */}
      <section className="hero relative py-5 pb-10 md:pt-32">
        <div className="container">
          <div className="text-center mb-10">
            <h1 className="text-graphite-black normal-case font-display mb-6 text-2xl dark:text-white lg:text-3xl xl:text-4xl">
              {t('home:news')}
            </h1>
          </div>
          <HomeNews />
        </div>
      </section>
    </>
  );
};

export default Hero;
