import {TopCollectionInterface} from '@api/collection';
import resolveImage from '@helpers/resolve-image';
import Link from 'next/link';

interface Column {
  name: string;
  className: string;
}
interface TopCollectionProps {
  collections: Array<TopCollectionInterface>;
  column: Array<Column>;
  large: boolean;
}

interface CardCollectionProps {
  collection: TopCollectionInterface;
  index: number;
}

const CardCollection = ({collection, index}: CardCollectionProps) => (
  <Link key={collection.id} href={'/collection/' + collection.slug}>
    <a style={{height: '150px'}} className="flex">
      <div className="flex w-full justify-start items-center">
        <div className="grow w-[60%] font-display">
          <div className="flex items-center">
            <span className="font-display text-left w-[20px]">{index}</span>
            <div className="flex flex-col sm:flex-row sm:gap-4 sm:items-center gap-2">
              <img
                src={`${resolveImage(collection.logo_image, 'images/banner-default.png')}`}
                alt={collection.name}
                width={100}
                height={100}
                // objectFit={'cover'}

                className="rounded-lg w-[100px] h-[100px]"
                // unoptimized
              ></img>

              <span className="font-display truncate">{collection.name}</span>
            </div>
          </div>
        </div>
        <div className="flex shrink w-[20%]">
          <span className="font-display">{collection.floorPrice.toFixed(2)} MATIC</span>
        </div>
        <div className="flex shrink w-[20%]">
          <span className="flex flex-col mt-6">
            <span className="font-display">{collection.totalVolumeNow.toFixed(2)} MATIC</span>
            {collection.percentChanges === 0 ? (
              <span className="font-display sm:text-right text-center">-</span>
            ) : (
              <span
                className={
                  collection.percentChanges > 0
                    ? 'font-display sm:text-right text-center text-green'
                    : 'font-display sm:text-right text-center text-red'
                }
              >
                {`${collection.percentChanges > 0 ? '+' : ''}${collection.percentChanges}%`}
              </span>
            )}
          </span>
        </div>
      </div>
    </a>
  </Link>
);

const TopCollection = ({collections, column, large}: TopCollectionProps) => {
  return (
    <>
      {large && collections.length > 5 ? (
        <>
          <div className="flex w-full gap-2 border-b border-black-green py-4">
            <div className="flex w-[50%] justify-end">
              {column.map((col, index) => (
                <div key={index} className={col.className}>
                  {col.name}
                </div>
              ))}
            </div>
            <div className="flex w-[50%] justify-end">
              {column.map((col, index) => (
                <div key={index} className={col.className}>
                  {col.name}
                </div>
              ))}
            </div>
          </div>

          <div className="flex w-full gap-2">
            <div className="flex w-[50%] flex-col justify-start gap-x-4">
              {collections?.slice(0, 5).map((collection, index) => (
                <CardCollection key={index} collection={collection} index={index + 1} />
              ))}
            </div>
            <div className="flex w-[50%] flex-col justify-start gap-x-4">
              {collections?.slice(5, collections.length).map((collection, index) => (
                <CardCollection key={index} collection={collection} index={5 + index + 1} />
              ))}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="flex justify-start border-b py-4 border-black-green gap-x-4">
            {column.map((col, index) => (
              <div key={index} className={col.className}>
                {col.name}
              </div>
            ))}
          </div>

          <div className="flex flex-col justify-start gap-x-4">
            {collections?.map((collection, index) => (
              <CardCollection key={index} collection={collection} index={index + 1} />
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default TopCollection;
