import React from 'react';
import Hero from '../../components/hero';
import Meta from '../../components/common/Meta';

const Home = () => {
  return (
    <main>
      <Meta title="Nusa NFT Marketplace" />
      <Hero />
    </main>
  );
};

export default Home;
