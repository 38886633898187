import {getArticles, NewsInterface} from '@api/common';
import Link from 'next/link';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import NewsItemHome from './newsItemHome';

const HomeNews = () => {
  const {t} = useTranslation();
  const [news, setNews] = useState<NewsInterface[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetchNews = async () => {
    setNews([]);
    setIsLoading(true);
    const res = await getArticles({page: 1, pageSize: 5, sort: 'desc'});
    if (res) {
      setNews(res?.data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchNews();
  }, []);

  return (
    <div className="w-full">
      {news ? (
        <>
          <div className="grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-4">
            {news.length > 4
              ? news.map((item: NewsInterface, index: number) => {
                  return (
                    <NewsItemHome key={item.id} type={index === 0 ? 'main' : ''} data={item} />
                  );
                })
              : news.length < 5
              ? news.map((item: NewsInterface, index: number) => {
                  return <NewsItemHome key={item.id} data={item} type="minimalize" />;
                })
              : null}
          </div>
        </>
      ) : (
        <div className="text-center">{t('home:no-news')}</div>
      )}
    </div>
  );
};

export default HomeNews;
